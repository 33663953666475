import React, { Component } from "react";
import { MDBAnimation, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer, MDBRow } from "mdbreact";

export class CarouselPage extends Component {
    render() {
        return (
            <div>
                <MDBContainer fluid className="hide-on-mob">
                    <MDBRow>
                        <MDBCarousel activeItem={1} length={3} showControls={true} showIndicators={false} id="Home_banner">
                            <MDBCarouselInner>
                                <MDBCarouselItem itemId="1">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander01.jpg"} alt="First slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">“Each body is different Therefore each rehabilitation must be different”</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="2">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander02.jpg"} alt="Second slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner2">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">“Being physically healthy is not expensive! Being injured is.So prevent injuries!”</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="3">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander03.jpg"} alt="Third slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">“Care yourself physically and mentally, you can keep on doing what you love for longer periods of time.”</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                                <MDBCarouselItem itemId="4">
                                    <MDBView><img className="d-block w-100" src={process.env.PUBLIC_URL + "/assets/images/home/Lander03.jpg"} alt="Third slide" /></MDBView>
                                    <MDBCarouselCaption>
                                        <div className="container-xl">
                                            <div className="carousel-caption-inner banner1">
                                                <MDBAnimation className="slideInUp" count={1} delay="0.25s" >
                                                    <h2 className="ttm-textcolor-darkgrey flipInY">When you take care of yourself physically and mentally, you can keep on doing what you love for longer periods of time.</h2>
                                                </MDBAnimation>
                                            </div>
                                        </div>
                                    </MDBCarouselCaption>
                                </MDBCarouselItem>
                            </MDBCarouselInner>
                        </MDBCarousel>
                    </MDBRow>
                </MDBContainer>
            </div>
        );
    }
}

export default CarouselPage;