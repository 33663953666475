import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class MandatorySidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to='/Mandatory-WGRC'>Wowen's Grievance and redressal Cell (WGRC)</NavLink></li>
                    <li><NavLink className="nav-link" to='/Mandatory-Anti-Ragging'>Anti - Ragging Committee</NavLink></li>
                    <li><NavLink className="nav-link" to='/Mandatory-SC-ST'>SC/ST Committee</NavLink></li>
                    <li><NavLink className="nav-link" to='/Mandatory-IQAC'>Internal Quality Assurance Cell (IQAC)</NavLink></li>
                </ul>
            </div>
        )
    }
}