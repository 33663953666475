import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class LifeCulture extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="KIPT Culture" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Culture-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Kempegowda Institute of Physiotherapy annually conducts Cultural fests and events. Its an extravagant celebration held for about 15 days followed by an annual day. the events include Fashion show, Dance, Singing, Rangoli, Mehandi, Face Painting, Cookking without fire, Best out of waste, Ikabana, Vegetable carving, Hair styling and so on.</p>
                                        <p className="about-para">All work and no play makes jack a dull boy’. True to this saying in mind and to break the monotony of lectures and clinics, students are encouraged to participate in the extracurricular activities. We take all efforts to unravel the hidden talents in our students. Annually the Institute conducts a variety of sports, cultural and literary competitions for the students to show case their talents. The best performers are even encouraged to take part in inter-collegiate and university level competitions.</p>
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

