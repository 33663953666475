import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class SpecialitiesSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to='/Specialities-Musculoskeletal'>Musculoskeletal</NavLink></li>
                    <li><NavLink className="nav-link" to='/Specialities-Sports'>Sports</NavLink></li>
                    <li><NavLink className="nav-link" to='/Specialities-Movement-Science'>Movement Science</NavLink></li>
                    <li><NavLink className="nav-link" to='/Specialities-Neurology'>Neurology</NavLink></li>
                    <li><NavLink className="nav-link" to='/Specialities-Pediatrics'>Pediatrics</NavLink></li>
                    <li><NavLink className="nav-link" to='/Specialities-Cardiorespiratory'>Cardiorespiratory</NavLink></li>
                    <li><NavLink className="nav-link" to='/Specialities-CBR'>Community Based Rehabilitation (CBR)</NavLink></li>
                </ul>
            </div>
        )
    }
}