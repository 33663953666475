import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutRules extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Rules and Regulations" image="banners/About.jpg" />

                {/* Content */}
                <section className="ttm-row service-section-style2 res-991-pb-0 ttm-bgcolor-grey clearfix">
                    <div className="container-xl">
                        <div className="row no-gutters">
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <div className="featured-content">
                                        <div className="featured-title"><h5>Service Rules</h5></div>
                                        <div className="featured-desc">
                                            <p className="text-justify">These Rules shall apply to all employees serving in connection with the affairs of Vokkaligara Sangha in respect of whose conditions of service the Sangha is competent to make rules.</p>
                                        </div>
                                    </div>
                                    <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white" href={process.env.PUBLIC_URL + "/assets/images/aboutrules/Service_Rules.pdf"} target="_blank" rel="noopener noreferrer" download style={{ position: "absolute", bottom: "10px" }}>Download PDF</a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="featured-icon-box style6">
                                    <div className="featured-content">
                                        <div className="featured-title"><h5>C and R Rules</h5></div>
                                        <div className="featured-desc">
                                            <p className="text-justify"> The Draft of the Cadre & Recruitment Rules, 2019, which the Administrator of the Rajya Vokkaligara Sangha,appointed by the State Government Under Section (27A) of Karnataka Society Registeration Act.</p>
                                        </div>
                                    </div>
                                    <br />
                                    <a className="ttm-btn ttm-btn-size-md ttm-bgcolor-darkgrey ttm-textcolor-white" href={process.env.PUBLIC_URL + "/assets/images/aboutrules/C&R_Rules.pdf"} target="_blank" rel="noopener noreferrer" download style={{ position: "absolute", bottom: "10px" }}>Download PDF</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
            </div>
        )
    }
}
