import React, { Component } from 'react';
import NoData from "../Common/NoData";
import Loading from "../Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class HodDisplay extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "FacultiesandStaff", "HOD");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                if (value.Department === this.props.department) {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Priority: value.Priority != undefined ? value.Priority : "999",
                            Image: value.Image,
                            Name: value.Name,
                            Description: value.Description,
                            Designation: value.Designation,
                            Qualification: value.Qualification,
                            Link: value.Link
                        }))
                }
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                {
                    this.state.Datas.length === 0 ? <Loading /> :
                        this.state.Datas === "NO_999" ? <NoData /> :
                            this.state.Datas.map((data, i) => (
                                <div className="row">
                                    <div className="col-lg-12 content-area order-lg-2">
                                        <div className="section-title with-desc text-left clearfix">
                                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                                <h4 className="title">Head of the Department</h4>
                                            </div>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="ttm_single_image-wrapper mb-35">
                                                        <img className="img-fluid" src={data.Image} title="single-img-twenty" alt="single-img-twenty" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8" style={{ margin: "auto" }}>
                                                    <div className="ttm-pf-single-detail-box mb-35">
                                                        <ul className="ttm-pf-detailbox-list">
                                                            <li><i className="fa fa-user" /><span>Name : </span>{data.Name}</li>
                                                            <li><i className="fas fa-id-card" /><span>Designation :</span>{data.Designation}</li>
                                                            <li><i className="fas fa-graduation-cap" /><span>Qualification :</span>{data.Qualification}</li>
                                                            <li><i className="fas fa-link" /><span>Read More : </span><a href={data.Link} target="_blank">View Details</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                }
            </div>
        )
    }
}
