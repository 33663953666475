import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class CampusSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to='/Campus-Companies'>Companies</NavLink></li>
                    <li><NavLink className="nav-link" to='/Campus-Clinics'>Physiotherapy Clinics</NavLink></li>
                    <li><NavLink className="nav-link" to='/Campus-Hospitals'>Hospitals</NavLink></li>
                    <li><NavLink className="nav-link" to='/Campus-Rehabilitation-Center'>Rehabilitation Center</NavLink></li>
                    <li><NavLink className="nav-link" to='/Campus-Sports-Clubs'>Sports Clubs</NavLink></li>
                </ul>
            </div>
        )
    }
}