import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export class Menu extends Component {
    render() {
        return (
            <nav id="menu" role="navigation" className="menu">
                <ul>
                    <li className="menu-hasdropdown"><a>About</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/About-Management'>About Management</NavLink></li>
                            <li><NavLink to='/About-College'>About College</NavLink></li>
                            <li><NavLink to='/About-Chairman'>Chairman Message</NavLink></li>
                            <li><NavLink to='/About-Principal'>Principal’s Desk</NavLink></li>
                            <li><NavLink to='/Faculty-Teching'>Teaching Staff</NavLink></li>
                            <li><NavLink to='/Faculty-NonTeching'>Administrative Staff</NavLink></li>
                            <li><NavLink to='/About-Rules'>Rules and Regulations</NavLink></li>
                            <li><NavLink to='/Mandatory-WGRC'>Mandatory Committees</NavLink></li>
                            <li><NavLink to='/College-Cultural'>College Committees</NavLink></li>
                        </ul>
                    </li>
                    <li><NavLink to="/Admission">Admission</NavLink></li>
                    <li className="menu-hasdropdown"><a>Programme</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Programme-BPT'>Bachelor of Physiotherapy</NavLink></li>
                            <li><NavLink to='/Programme-MPT'>Master of Physiotherapy</NavLink></li>
                            <li><NavLink to='/Programme-PHD'>Doctor of Philosophy</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Specialities</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Specialities-Musculoskeletal'>Musculoskeletal</NavLink></li>
                            <li><NavLink to='/Specialities-Sports'>Sports</NavLink></li>
                            <li><NavLink to='/Specialities-Movement-Science'>Movement Science</NavLink></li>
                            <li><NavLink to='/Specialities-Neurology'>Neurology</NavLink></li>
                            <li><NavLink to='/Specialities-Pediatrics'>Pediatrics</NavLink></li>
                            <li><NavLink to='/Specialities-Cardiorespiratory'>Cardiorespiratory</NavLink></li>
                            <li><NavLink to='/Specialities-CBR'>Community Based Rehabilitation (CBR)</NavLink></li>
                        </ul>
                    </li>
                    <li className="menu-hasdropdown"><a>Life @ KIPT</a>
                        <ul className="menu-dropdown">
                            <li><NavLink to='/Life-OPD-IPD'>OPD/IPD</NavLink></li>
                            <li><NavLink to='/Life-Camps'>Free Camps</NavLink></li>
                            <li><NavLink to='/Life-Culture'>KIPT Culture</NavLink></li>
                            <li><NavLink to='/Life-Graduation'>Graduation Day</NavLink></li>
                            <li><NavLink to='/Life-Education'>Education Fair</NavLink></li>
                            <li><NavLink to='/Life-Facilities'>Facilities</NavLink></li>
                            <li><NavLink to='/Alumni'>Alumni</NavLink></li>
                            <li><NavLink to='/Library'>Library</NavLink></li>
                        </ul>
                    </li>
                    <li><NavLink to="/Campus-Companies">Placements</NavLink></li>
                    <li><NavLink to="/Contact">Contact</NavLink></li>
                </ul>
            </nav>

        )
    }
}