import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutCollege extends Component {
    render() {
        return (
            <div>
                <Header />
                <Banner title="About College" image="banners/About.jpg" />
                
                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutcollege/college-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">The BPT bachelor’s degree program was started in the year 1997 by the Rajya Vokkaligara Sangha with permission from the Government of Karnataka and Rajiv Gandhi University of Health Sciences, Bangalore-41. With an intake of 25 students. We have gained an independent identity with the name “kempegowda institute of physiotherapy” named after the ruler “Sri Kempegowda”, the founder of Bangalore city. Now, the institute has started MPT Post Graduate programe in the year 2004 with intake 05 seats and Ph.D programme in the year 2022 with 02 Ph.D Guides (Orthopeadics-1 &amp; Neurology-1) . Institute has an annual intake of 60 students in BPT and 20 in MPT. In view of the changing health care services world wide and a wider acknowledgement about the importance of physical rehabilitation of the disabled, physiotherapy gained importance in last 50 years in western countries. The ripple set waves in India as well. The Rajya Vokkaligara Sangha felt the need to train our people in this area to complete their mission of giving total health care to the needy and poor.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">Kempegowda Institute of Physiotherapy firmly believes that the learning experience extends far beyond the four walls of the class rooms. Hence it has created to finest campuses, one of our Campus located at Banashankari 2nd stage for Pre and Para clinical subjects with both Boys and Girls Hostel facility. Our Central Library is located at Banashankari Campus and KIMS Hospital Campus. We Have KIMS Hospital and Research Center with 1400 beded Multi-Specialty and Super-Specialty Hospital. Our KIPT College has independent College building, independent In-patient and Out-Patient Departments in the area of 30,000 Sqft in the heart of the silicon city. We have independent auditorium and administrative block.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">During Clinical training our Students are posted at the advance Physiotherapy clinic with respective Specialty Doctors in our In-patient block which is located in KIMS Hospital and Research Center this provide them with excellent clinical exposure.</p>
                                    </div>
                                </div>
                            </div>
                            <br /><br /><br /><br /><br />
                            <section className="paddingtop-2">
                                <div style={{ paddingBlockEnd: "40px" }}>
                                    <div className="row no-gutters mt_100 res-991-mt-0">
                                        <div className="col-md-6">
                                            <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                                                <div className="layer-content">
                                                    <div className="spacing-2">
                                                        <div className="section-title ttm with-desc clearfix"><div className="title-header"><h4 className="title">Vision</h4></div></div>
                                                        <div className="ttm-progress-bar">
                                                            <p className="about-para">To evolve as the institution of excellence in the field of physiotherapy by providing quality education and research opportunity emphasizing on ethics and compassion creating inclusive professionals.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="col-bg-img-two ttm-col-bgimage-yes ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                                                <div className="section-title ttm with-desc clearfix">
                                                    <div className="spacing-2">
                                                        <div className="title-header"><h4 className="title">Mission</h4></div>
                                                        <div>
                                                            <ul>
                                                                <li>* To promote core education approaches.</li>
                                                                <li>* To support relevant research activities.</li>
                                                                <li>* To develop scientific competencies and value system.</li>
                                                                <li>* To foster the development of community extension.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        )
    }
}
