import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Layout
import { Footer } from './components/layout/Footer';

// pages
import Home from './components/Home';

import AboutManagement from "./components/about/AboutManagement";
import AboutCollege from "./components/about/AboutCollege";
import AboutChairman from "./components/about/AboutChairman";
import AboutPrincipal from "./components/about/AboutPrincipal";
import AboutRules from './components/about/AboutRules';

import FacultyTeching from './components/about/FacultyTeching';
import FacultyNonTeaching from './components/about/FacultyNonTeaching';

import MandatoryWGRC from './components/about/MandatoryWGRC';
import MandatoryAntiRagging from './components/about/MandatoryAntiRagging';
import MandatorySCST from './components/about/MandatorySCST';
import MandatoryIQAC from './components/about/MandatoryIQAC';
import CollegeCultural from './components/about/CollegeCultural';
import CollegeSports from './components/about/CollegeSports';
import CollegeLibrary from './components/about/CollegeLibrary';
import CollegeWebsite from './components/about/CollegeWebsite';

import Admission from './components/Admission/Admission';

import ProgrammeBPT from "./components/programme/ProgrammeBPT";
import ProgramMPT from "./components/programme/ProgramMPT";
import ProgramPHD from './components/programme/ProgramPHD';

import SpecialitiesMusculoskeletal from "./components/Specialities/SpecialitiesMusculoskeletal";
import SpecialitiesSports from "./components/Specialities/SpecialitiesSports";
import SpecialitiesMovementScience from "./components/Specialities/SpecialitiesMovementScience";
import SpecialitiesNeurology from "./components/Specialities/SpecialitiesNeurology";
import SpecialitiesPediatrics from "./components/Specialities/SpecialitiesPediatrics";
import SpecialitiesCardiorespiratory from "./components/Specialities/SpecialitiesCardiorespiratory";
import SpecialitiesCBR from "./components/Specialities/SpecialitiesCBR";

import LifeOPD_IPD from './components/lifekipt/LifeOPD_IPD';
import LifeFreeCampus from "./components/lifekipt/LifeFreeCampus";
import LifeCulture from "./components/lifekipt/LifeCulture";
import LifeGraduation from "./components/lifekipt/LifeGraduation";
import LifeEducationFair from "./components/lifekipt/LifeEducationFair";
import LifeFacilities from "./components/lifekipt/LifeFacilities";
import Library from "./components/lifekipt/Library";

import CampusCompanies from './components/Placements/CampusCompanies';
import CampusClinics from './components/Placements/CampusClinics';
import CampusHospitals from './components/Placements/CampusHospitals';
import CampusRehabilitationCenter from './components/Placements/CampusRehabilitationCenter';
import CampusSportsClubs from './components/Placements/CampusSportsClubs';

import Alumni from './components/Alumni';
import Gallery from "./components/Gallery";
import Contact from './components/Contact';

import sent from './components/sent';
import ScrollToTop from './components/layout/Gotop';

const appHistory = {basename: process.env.PUBLIC_URL};
const history = createBrowserHistory(appHistory);
function App() {

  return (
    <div className="page">
      <Router basename={process.env.PUBLIC_URL} history={history}>
        <div id="preloader"><div id="status">&nbsp;</div></div>
        <ScrollToTop />
        <Route exact path='/' component={Home} />

        <Route path="/About-Management" component={AboutManagement} />
        <Route path="/About-College" component={AboutCollege} />
        <Route path="/About-Chairman" component={AboutChairman} />
        <Route path="/About-Principal" component={AboutPrincipal} />
        <Route path="/About-Rules" component={AboutRules} />

        <Route path="/Faculty-Teching" component={FacultyTeching} />
        <Route path="/Faculty-NonTeching" component={FacultyNonTeaching} />

        <Route path="/Mandatory-WGRC" component={MandatoryWGRC} />
        <Route path="/Mandatory-Anti-Ragging" component={MandatoryAntiRagging} />
        <Route path="/Mandatory-SC-ST" component={MandatorySCST} />
        <Route path="/Mandatory-IQAC" component={MandatoryIQAC} />

        <Route path="/College-Cultural" component={CollegeCultural} />
        <Route path="/College-Sports" component={CollegeSports} />
        <Route path="/College-Library" component={CollegeLibrary} />
        <Route path="/College-Website" component={CollegeWebsite} />

        <Route path="/Admission" component={Admission} />

        <Route path="/Programme-BPT" component={ProgrammeBPT} />
        <Route path="/Programme-MPT" component={ProgramMPT} />
        <Route path="/Programme-PHD" component={ProgramPHD} />

        <Route path="/Specialities-Musculoskeletal" component={SpecialitiesMusculoskeletal} />
        <Route path="/Specialities-Sports" component={SpecialitiesSports} />
        <Route path="/Specialities-Movement-Science" component={SpecialitiesMovementScience} />
        <Route path="/Specialities-Neurology" component={SpecialitiesNeurology} />
        <Route path="/Specialities-Pediatrics" component={SpecialitiesPediatrics} />
        <Route path="/Specialities-Cardiorespiratory" component={SpecialitiesCardiorespiratory} />
        <Route path="/Specialities-CBR" component={SpecialitiesCBR} />

        <Route path="/Life-OPD-IPD" component={LifeOPD_IPD} /> 
        <Route path="/Life-Camps" component={LifeFreeCampus} />
        <Route path="/Life-Culture" component={LifeCulture} />
        <Route path="/Life-Graduation" component={LifeGraduation} />
        <Route path="/Life-Education" component={LifeEducationFair} />
        <Route path="/Life-Facilities" component={LifeFacilities} />
        <Route path="/Library" component={Library} />

        <Route path="/Campus-Companies" component={CampusCompanies} />
        <Route path="/Campus-Clinics" component={CampusClinics} />
        <Route path="/Campus-Hospitals" component={CampusHospitals} />
        <Route path="/Campus-Rehabilitation-Center" component={CampusRehabilitationCenter} />
        <Route path="/Campus-Sports-Clubs" component={CampusSportsClubs} />

        <Route path="/Alumni" component={Alumni} />
        <Route path="/Gallery" component={Gallery} />
        <Route path="/contact" component={Contact} />

        <Route path="/Sent" component={sent} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
