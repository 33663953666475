import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class ProgrammeBPT extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Bachelor of Physiotherapy (BPT)" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="section-title with-desc text-left clearfix paddingtop-1">
                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                <h5 className="title">BPT Government Seat - Basic Details</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Course duration - 4 years</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Internship - 6 months</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Seats Available - 12</li>
                                </ul>
                            </div>
                        </div>
                        <div className="ttm-service-single-content-area">
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">BPT Government Seats Admission Procedure</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Karnataka Education Authority (KEA) Government of Karnataka allots Governments Seats to KIPT.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Allotted Students Brings KEA allotment letter while admission.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Required Documents for Admission are 10th original Marks card, 12th original Marks card, Transfer certificate, 06 passport size photo, Study Certificate.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Tuition fee Should be paid through cash through college challans or D.D. in favour of The Principal KIPT, Bangalore. RGUHS fee and Application fee should be paid in cash through college challans only.</li>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Eligibility</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Students 12th (II PUC) Secured Marks 45% in Physics, Chemistry &amp; Biology (PCB).</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Admission Contact Details</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Contact person Principal and Admission Committee Members. Dr. Prem Kumar B. N, Principal - 9845047646, Office No-080-26619960</p>
                                <a href={process.env.PUBLIC_URL + `/assets/images/programme/BPT Application.pdf`} target="_blank">Download Application Form</a>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">BPT Management Seats - Basic Details</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Course duration - 4 years</li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Internship - 6 months</li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Seats Available - 48</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h5 className="title">BPT Management Seats Admission Procedure</h5>
                                    </div>
                                </div>
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Required Documents for Admission are 10 th original Marks card, 12th original Marks card, Transfer certificate, 06 passport size photo, Study Certificate and Migration certificate.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Tuition fee Should be paid through cash through college challans or D.D. in favour of The Principal KIPT, Bangalore. Rajya Vokkaligara Sangha fee, RGUHS fee and Application fee should be paid in cash through college challans only.</li>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Eligibility</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Students 12th (II PUC) Secured Marks 40% in Physics, Chemistry &amp; Biology (PCB).</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Admission Contact Details</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Contact person Principal and Admission Committee Members. Dr. Prem Kumar B. N, Principal - 9845047646, Office No-080-26619960</p>
                                <a href={process.env.PUBLIC_URL + `/assets/images/programme/BPT Application.pdf`} target="_blank">Download Application Form</a>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

