import styled from "styled-components";
import { colors } from "../element/elements.js";

export const Styles = styled.div`

    .bitHeader-area {        
        background-size    : cover;
        background-position: center;
        background-repeat  : no-repeat;
        padding            : 10rem 60px 3rem;
        position           : relative;

        &:before {
            position  : absolute;
            content   : '';
            opacity   : 0.5;
            width     : 100%;
            height    : 100%;
            top       : 0;
            left      : 0;
        }

        hr {
            height : 10px;
        }

        h2{
            color: ${colors.black2};
            font-weight: 600;
            padding-bottom: 10px;
            margin-bottom: 15px;
            position: relative;
            padding   : 30px 45px 10px 0;
            &:before {
                position: absolute;
                content: "";
                background: ${colors.bg3};
                width: 50px;
                height: 3px;
                bottom: 0;
                left: 0;
            }

            @media(max-width: 767px) {
                font-size : 20px;
            }
        }

        @media(max-width: 767px) {
            padding   : 10rem 10px 1rem;
        }
    }
`;