import React, { Component } from 'react';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import 'react-image-lightbox/style.css';
import Notification from './Common/Notification';
import StudentAchivers from '../components/home/StudentAchivers';
import FacultyAchivers from '../components/home/FacultyAchivers';
import Events from '../components/home/Events';
import { Link } from 'react-router-dom';

export class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    return (
      <div className="site-main">
        <Header />
        <CarouselPage />
        <Notification />

        {/* Why Choose */}
        <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
          <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-8 offset-lg-2">
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title"><span>WHY CHOOSE KIPT?</span></h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="ttm-service-description">
              <div className="row">
                <div className="col-md-5">
                  <div className="ttm_single_image-wrapper">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/aboutcollege/college-1.jpg"} alt="single-img-eighteen" />
                  </div>
                </div>
                <div className="col-md-7 justify" style={{ margin: "auto" }}>
                  <p className="about-para">Physiotherapy being a very developing and enterprising course, it has got excellent employment opportunities in speciality hospitals both in Government and Private sectors and also in academics. Physiotherapists can do their private practice by opening their own clinics or doing home visits, which does not need any major investment. It has got excellent career opportunities both in India and abroad. Specialization can be obtained by doing Master's degree (MPT) after completing the graduation course. The students can take up Ph.D. after completing their Masters degree.</p>
                  <p>Physiotherapy is an evolving profession worldwide which work for the benefit and rehabilitation of the differently able individuals. Physiotherapy means physio therapeutic system of medicine which includes examination, treatment, advice and instructions to any person preparatory to or for the purpose of or in connection with movement dysfunction,</p>
                </div>
                <div className="col-md-12 justify paddingtop-1">
                  <p className="about-para">body malfunction, physical disorder, disability, healing and pain from trauma and disease, physical and mental conditions using physical agents including exercise, mobilization, manipulation, mechanical and electrotherapy, activity and devices or diagnosis, treatment and prevention. It is a health care profession which enables an individual to do what he needs to do and wants to do. Physiotherapy addresses the physical, cognitive, psycho social, sensory, and other aspects of performance in a variety of environment to support engagement in everyday life activities that affect health, well being, and quality of life.</p>
                </div>
                <div className="col-md-12 justify paddingtop-1">
                  <div className="section-title style3 mb-0 clearfix">
                    <div className="title-header mb-0">
                      <h2 className="title ttm-textcolor-white"><span className="ttm-textcolor-white" style={{ color: "#0f6464" }}>UG Programme - Bachelor of Physiotherapy</span></h2>
                    </div>
                  </div>
                  <p className="about-para paddingtop-1">Course duration- 4 years / Internship - 6 months</p>
                  <p className="about-para">It is designed to provide students with the working knowledge of the structure of the human body which is essential foundation for their clinical studies. Studies are concerned with the topographical and functional anatomy of the limbs and thorax. Particular attention is paid to the muscles, bones and joints of the regions. The abdomen, pelvis, perineum, head and neck and central nervous system (CNS) are studied with particular reference to topics of importance to physiotherapists. The study of the CNS includes detailed consideration of the control of motor function.</p>
                  <Link to="/Admission"><b>Click Here to Know More</b></Link>
                </div>
                <div className="col-md-12 justify paddingtop-1">
                  <div className="section-title style3 mb-0 clearfix">
                    <div className="title-header mb-0">
                      <h2 className="title ttm-textcolor-white"><span className="ttm-textcolor-white" style={{ color: "#0f6464" }}>PG Programme - Master of Physiotherapy</span></h2>
                    </div>
                  </div>
                  <p className="about-para paddingtop-1">Course duration- 2 years</p>
                  <p className="about-para">The Masters Degree in Physiotherapy is a two year program consisting of classroom teaching, self academic activities and clinical posting. In the first year theoretical basis of physiotherapy is refreshed along with research methodology and biostatistics. The students are rotated in all areas of clinical expertise during this period. They are required to choose their study for dissertation and submit a synopsis. During the second year the students will be posted in their area of specialty. They are required to complete and submit their dissertation. The learning program includes seminars, journal reviews, case presentations, case discursions and classroom teaching. Some of the clinical postings are provided at other reputed centers in the country in order to offer a wider spectrum of experience. The students are encouraged to attend conference, workshop to enhance their knowledge during the course of study. University examinations are held at the end of second year.</p>
                  <Link to="/Admission"><b>Click Here to Know More</b></Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Uniqueness */}
        <section className="ttm-row bottomzero-padding-section position-relative clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12">
                <div className="section-title text-center with-desc clearfix">
                  <div className="title-header"><h2 className="title">Uniqueness of <span>KIPT</span></h2></div>
                </div>
              </div>
            </div>
            <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique1.jpg`} alt="image" />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">First set A Grade Colleges.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique2.jpg`} alt="image" />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">Separate in Patients wing in KIMS hospital.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique3.jpg`} alt="image" />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">Started in 1997 - First of few Colleges started under RGUHS.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique4.jpg`} alt="image" />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">All teaching faculties are PG Guides.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique5.jpg`} alt="image" />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">KIPT attached to 1400 beds KIMS Hospital & Medical College.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                <div className="featured-imagebox featured-imagebox-portfolio style2">
                  <div className="featured-thumbnail">
                    <img className="img-fluid" src={process.env.PUBLIC_URL + `/assets/images/home/Unique6.jpg`} alt="image" />
                  </div>
                  <div className="featured-content">
                    <div className="category">
                      <p className="text-justify">Located in the Heart of city.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Events */}
        <Events />

        {/* Student Achivers */}
        <StudentAchivers />

        {/* Faculty Achivers */}
        <FacultyAchivers />

      </div>
    )
  }
}

export default Home;