import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class ProgramMPT extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Master of Physiotherapy (MPT)" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="section-title with-desc text-left clearfix paddingtop-1">
                            <div className="title-header" style={{ borderTopColor: "red" }}>
                                <h5 className="title">MPT Government Seat - Basic Details</h5>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Course duration - 2 years</li>
                                </ul>
                            </div>
                            <div className="col-md-4">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Seats Available - 05</li>
                                </ul>
                            </div>
                        </div>
                        <div className="ttm-service-single-content-area">
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">MPT Government Seats Admission Procedure</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Karnataka Education Authority (KEA) Government of Karnataka allots Governments Seats to KIPT.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Allotted Students Brings KEA allotment letter while admission.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Required Documents - 10th original Marks card, 12th original Marks card, All 4 years B.P.T original marks card, Migration certificate, Transfer Certificate, PDC/Degree Certificate, Internship completion Certificate, 06 passport size photo.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Tuition fee Should be paid through cash through college challans or D.D. in favour of The Principal KIPT, Bangalore. RGUHS fee and Application fee should be paid in cash through college challans only.</li>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Eligibility</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Students Secured 50% Marks in BPT.</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Admission Contact Details</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Contact person Principal and Admission Committee Members. Dr. Prem Kumar B. N, Principal - 9845047646, Office No-080-26619960</p>
                                <a href={process.env.PUBLIC_URL + `/assets/images/programme/MPT Application.pdf`} target="_blank">Download Application Form</a>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">MPT Management Seats - Basic Details</h5>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Course duration - 2 years</li>
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Seats Available - 15</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <div className="section-title with-desc text-left clearfix paddingtop-2">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h5 className="title">MPT Management Seats Admission Procedure</h5>
                                    </div>
                                </div>
                                <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The students who are interested to join Kempegowda Institute of Physiotherapy, under management quota can contact Principal KIPT.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Required Documents - 10th original Marks card, 12th original Marks card, All 4 years B.P.T original marks card, Migration certificate, Transfer Certificate, PDC/Degree Certificate, Internship Completion Certificate, 06 passport size photo. MPT- Other than RGUHS Universities all over India and Abroad:- 10th original Marks card, 12th original Marks card, All 4 years B.P.T original marks card, Migration certificate, Transfer Certificate, PDC/Degree Certificate, Internship Completion Certificate, and Eligibility Certificate from RGUHS. 06 passport size photo.</li>
                                    <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Tuition fee Should be paid through cash through college challans or D.D. in favour of The Principal KIPT, Bangalore. Rajya Vokkaligara Sangha fee, RGUHS fee and Application fee should be paid in cash through college challans only.</li>
                                </ul>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Eligibility</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Students Secured 50% Marks in BPT</p>
                            </div>
                            <div className="section-title with-desc text-left clearfix paddingtop-2">
                                <div className="title-header" style={{ borderTopColor: "red" }}>
                                    <h5 className="title">Admission Contact Details</h5>
                                </div>
                            </div>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Contact person Principal and Admission Committee Members. Dr. Prem Kumar B. N, Principal - 9845047646, Office No-080-26619960</p>
                                <a href={process.env.PUBLIC_URL + `/assets/images/programme/MPT Application.pdf`} target="_blank">Download Application Form</a>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

