import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import NoData from "../../components/Common/NoData";
import Loading from "../../components/Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';
import CollegeSidebar from './CollegeSidebar';

export default class CollegeWebsite extends Component {

  state = { Datas: [] }

  async componentDidMount() {
    //For Teaching
    const docRef = doc(firestore, "About", "CollegeWebsite");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      var Data = []
      Object.entries(docSnap.data()).forEach(([key, value]) => {
        Data.push(Object.assign(
          {
            Id: key,
            Priority: value.Priority != undefined ? value.Priority : "999",
            Image: value.Image,
            Name: value.Name,
            Designation: value.Designation
          }))
      });

      Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

    }

    else { this.setState({ Datas: "NO_999" }) }
  }

  render() {
    return (
      <div className="body_wrapper">

        <Header />
        <Banner title="College Committees" image="banners/About.jpg" />

        {/* Content */}
        <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
          <div className="container-xl">
            <div className="row">
              <div className="col-lg-9 content-area order-lg-2">
                <div className="row paddingtop-2">
                  {
                    this.state.Datas.length === 0 ? <Loading /> :
                      this.state.Datas === "NO_999" ? <NoData /> :
                        this.state.Datas.map((data, i) => (
                          <div className="col-lg-6" key={i}>
                            <div className="row">
                              <div className="col-lg-4">
                                <div className="gallery-box">
                                  <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                </div>
                              </div>
                              <div className="col-lg-8" style={{ margin: "auto" }}>
                                <p style={{ color: "black", fontWeight: "bold" }}>{data.Name}</p>
                                <p style={{ color: "black" }}>{data.Designation}</p>
                              </div>
                            </div>
                            <br />
                          </div>
                        ))
                  }
                </div>
                <br />
              </div>
              <div className="col-lg-3 widget-area">
                <CollegeSidebar />
              </div>
            </div>
          </div>
        </div>

      </div>
    )
  }
} 