import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutPrincipal extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Principal Desk" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="ttm-featured-wrapper">
                                    <div className="featured-thumbnail text-center">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/aboutprincipal/principal.jpg`} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 justify" style={{ margin: "auto" }}>
                                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                    <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                        <div className="ttm-team-member-single-list">
                                            <h2 className="ttm-team-member-single-title">Dr. PREM KUMAR.B. N </h2>
                                            <span className="ttm-team-member-single-position" style={{fontWeight:"500"}}>MPT(Neuro), PhD.<br/>Designation : Principal<br/>Ph.No : +91 9845047646 <br/>Email : kimsphysiotherapy@gmail.com</span>
                                            <hr />
                                            <p>A warm welcome to Kempegowda Institute of PhysioTherapy, Bangalore. Physiotherapy is growing in giant strides, and we are experiencing advancements in all the spheres every day. Since the inception of our Institution in 1997, we believe that a Best Physiotherapist must possess utmost knowledge in every medical speciality and have excellent values and ethical attributes. This is derived from the knowledge of technological growths perceived by HUMANE TOUCH.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 paddingtop-2 justify'>
                                <p>We here believe that “Growth is Development”. We reach every individual with needs of rehabilitation and care for progress of wellbeing.</p>
                                <p>We endeavour to provide our students a world class educational experience and our multi-disciplinary campus offers them a great future to excel in their chosen speciality.</p>
                                <p>Throughout the years we have learnt with this quote “PHYSIOTHERAPY IS NOT JUST ABOUT STRENGTHENING MUSCLES, ITS ABOUT EMPOWERING PEOPLE. Also, ‘PHYSIOTHERAPY IS NOT A PUNISHMENT FOR BEING INJURED OR DISABLED, IT’S A CELEBRATION OF WHAT YOUR BODY CAN STILL DO- unknown’</p>
                                <p>Our aim is to train the students to know the patient’s perspective to create better nexus between healthcare and general populace. Our students are trained to treat every individual efficiently and effectively irrespective of race, gender ethnicity or social attributes. We trust in the growth of healthy community and in turn growth of our NATION.</p>
                                <p>We have most eligible and well experienced faculties with Doctorate in Physiotherapy, Post Graduation and with good experience in both teaching and clinical front. We focus on thorough training of Graduate (BPT), Postgraduate (MPT) and Doctoral students (PhD) who are the future torch bearers of the profession.</p>
                                <p>With the incessant inspiration of our Chairman, Management directors, active participation of teachers and staffs of Kempegowda Institute of PhysioTherapy is persistently endeavouring to attain academic excellence and create excellent future for our students.</p> 
                                <p>We are grateful to the Management, Faculties, Alumni for all the support given to our institution. And I believe that ‘Working together in HARMONY is walking towards GLORY’.</p>
                                <p> I wish all the students a rewarding and successful future.</p>
                                <p><b style={{ fontWeight: "bold" }}>Dr. PREM KUMAR B N</b><br /> Principal</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
