import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import NoData from "./../../components/Common/NoData";
import Loading from "./../../components/Common/Loading";
import ModalImage from "react-modal-image";
import { firestore } from './../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';

export default class LifeFreeCampus extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "lifeKIPT", "FreeCamps");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                Data.push(Object.assign(
                    {
                        Id: key,
                        Priority: value.Priority != undefined ? value.Priority : "999",
                        Image: value.Image,
                        Header: value.Description,
                    }))
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Free Camps" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Camp-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Kemepgowda Institute of Physiotherapy conducts free camps annually across the city through Community Based Rehabilitation for disabled and needy. These camps will be usually held at BBMP Ground, Richmond Town, Parvathi Puram. Sajjan Rao Vidhya Samasthe, CP School (Conducted awareness camp).</p>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
                {/* Dynamic Data */}
                <div className="container">
                    <div className="row">
                        {
                            this.state.Datas.length === 0 ? <Loading /> :
                                this.state.Datas === "NO_999" ? <NoData /> :
                                    this.state.Datas.map((data, i) => (
                                        <div className="col-lg-3 paddingtop-1" key={i}>
                                            <div className="gallery-box">
                                                <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="" />
                                            </div>
                                            <br />
                                            <p style={{ color: "black", textAlign: "center" }}>{data.Header}</p>
                                        </div>
                                    ))
                        }
                    </div>
                    <br />
                </div>
            </div>
        )
    }
}

