import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class AboutChairman extends Component {

    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Chairman's Message" image="banners/About.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="ttm-featured-wrapper">
                                    <div className="featured-thumbnail text-center">
                                        <img src={process.env.PUBLIC_URL + `/assets/images/aboutchairman/chairman.jpg`} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9 justify" style={{ margin: "auto" }}>
                                <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                    <div className="ttm-team-member-content shadow-box res-991-mt-30">
                                        <div className="ttm-team-member-single-list">
                                            <h2 className="ttm-team-member-single-title">Sri B P Manje Gowda</h2>
                                            <span className="ttm-team-member-single-position">Chairman, KIPT, RVS</span>
                                            <hr />
                                            <p><b>As said,”Education is one thing no one can take away from you.” — Elin Nordegren</b></p>
                                            <p>We welcome you to Kempegowda Institute of Physiotherapy, where we groom young minds to become responsible and efficient citizens of INDIA.</p>
                                            <p>We are established and esteemed institution, maintaining respectable academic standard, with good conduct of discipline with which we grace our students with remarkable excellent percentage of results. We strive hard to provide best academic and clinical training to a student who truly achieve success with his or her skills in any chosen field.</p>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12 paddingtop-2 justify'>
                                <p>As Chairman of this prestigious institution built by visionaries and pioneers, I look forward for full cooperation and support of Parents and students to possibly transform the ambitions and dreams to reality of every pupil walking through our doors where they are nurtured and equipped with immense knowledge and globalization awareness of education across any culture and geographical boundaries.</p>
                                <p>Our institution provides education in Physiotherapy at Undergraduate (BPT), Postgraduate (MPT) and PhD (Doctorate) levels. We have well educated, clinically experienced, research-oriented Teachers and committed Non-teaching staffs to make certain that the students will be enterprising and competent enough in the society.</p>
                                <p>Our training ensures that every student of Kempegowda Institute of Physiotherapy is well placed and well established in the all the fields of Health care and rehabilitation.</p>
                                <p>I wish each one of our students, alumni and future students all the very best.</p>
                                <p>With Warm regards,</p>
                                <p><b style={{ fontWeight: "bold" }}>Sri.B.P.MANJE GOWDA.</b><br />Chairman, KIPT, RVS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
