import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

export default class CollegeSidebar extends Component {
    render() {
        return (
            <div className="widget widget-nav-menu">
                <ul className="widget-menu">
                    <li><NavLink className="nav-link" to='/College-Cultural'>Cultural Committee</NavLink></li>
                    <li><NavLink className="nav-link" to='/College-Sports'>Sports Committee</NavLink></li>
                    <li><NavLink className="nav-link" to='/College-Library'>Library Committee</NavLink></li>
                    <li><NavLink className="nav-link" to='/College-Website'>Website Editorial Committee</NavLink></li>
                </ul>
            </div>
        )
    }
}
