import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class Admission extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Admission" image="banners/Admission.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="section-title with-desc text-left clearfix paddingtop-1">
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Scope for Physiotherapy</h4>
                                    </div>
                                    <p class="justify">Physiotherapy being a very developing and enterprising course all over the globe, it has got excellent employment opportunities in all speciality hospitals both in Government and Private sectors and also in academics. Physiotherapists can do their private practice by opening their own clinics or doing home visits, which does not need any major investment. It has got excellent career opportunities both in India and abroad. Specialization can be obtained by doing Master&#39;s degree (MPT) after completing the graduation course. The students can take up Ph.D. after completing their Masters degree in various specialities..</p>
                                    <p class="justify">Physiotherapy is an evolving profession worldwide which work for the benefit and rehabilitation of the differently able individuals. Physiotherapy means therapeutic system of medicine which includes examination, treatment, advice and instructions to any person prepare to come out of movement dysfunction, body malfunction, physical disorder, disability, healing pain from trauma and disease further the physical and mental conditions using physical agents including exercise, mobilization, manipulation, mechanical traction and electrotherapy traction. further activity and diagnosis treatment and prevention. It is a health care profession which enables an individual to do what he needs to do and wants to do. Physiotherapy addresses the physical, cognitive, psycho social, sensory, and other aspects of performance in a variety of environment to support engagement in everyday life activities that affect health, well being, and quality of life.</p>
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h5 className="title">Key Points</h5>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>Higher studies</b> : The completion of the bachelor’s degree course in the field of physiotherapy opens up multiple avenues for further higher studies in the field of Physiotherapy. The undergraduate students are eligible for Masters in Physiotherapy in various specialities in India abroad countries like USA, Canada, UK, Australia and New Zealand. They are also eligible to do Doctorate in Physiotherapy in USA, India and other foreign Countries. Apart from this students can also pursue studies in varied streams like MSc Anatomy, Physiology, Post Graduate diploma in sports sciences, Hospital Administration etc.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i><b>Job Prospects</b> : In India first contact practice and referral practice is possible with the minimal investment needed to set up a basic physiotherapy clinic, but physiotherapy profession has a very huge potential which would enable the professional to lead a very successful and decent life in the society.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>It has become a part and parcel of any corporate set up hospital to have a full fledged physiotherapy department in various speciailities and there are lot of job openings  in academics and clinical physiotherapist post in india and all over the globe.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>As fitness nowadays is not a partial statement but a serious pursuit for many individual Instead of going for non professional and not scientific methods of training, people are aware that physiotherapist can give tailor made fitness programs to the individual’s requirements.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The course conducted in our college is recognized by various international credentialing agencies, which makes our college  students considered competent to work in overseas Physiotherapists as well as academician.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>From India the Physiotherapy professional have already created a academic excellence globally. There is tremendous opening for Indian therapist to work in developed nations like the USA, UK, Ireland, Australia, New Zealand, Singapore and all Middle East countries.</li>
                                    </ul>
                                    <div className="title-header paddingtop-1" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Under Graduate (BPT)</h4>
                                    </div>
                                    <p class="justify">Physiotherapy is an independent profession, which deals with prevention, treatment and rehabilitation of various Musculoskeletal,  Neurological, chest and other medical disorders by means of electrotherapy, exercise therapy and manual therapy. As Physiotherapy has got important role to play in different types of medical and all varieties of surgical cases, the course is structured around subjects like Anatomy, Physiology, Pathology, Microbiology, Biomechanics, Exercise Therapy, Electrotherapy, Orthopaedics, Neurology, General Medicine, General Surgery, Paediatrics, ENT, Ophthalmology, Dermatology and Gynecology.</p>
                                    <p class="justify">The Bachelor of Physiotherapy program is an eclectic blend of subject modules which encompasses the concepts of basic sciences, Pre-clinical, Para clinical, Clinical and Physiotherapy subjects including research and clinical practice. At KIPT our experienced teaching faculties nourishes the clinical and academic aspirants of KIPT to concentrate and focus on scientific learning and various hands on skills. The curriculum offered by Rajiv Gandhi University of Health Sciences is overhauled to develop the clinical practices as a common form for all the KIPT students. The objective of physiotherapy is to provide therapeutic interventions that help people to recover from physical ailments and to elevate their quality of life. If a student is inclined towards service-oriented sectors they can opt for clinical practices after completion of the Physiotherapy program and to achieve that all corporate set up hospitals may be the apt choice for excelling in their career.</p>
                                    <div className="title-header paddingtop-1" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Post Graduate (MPT)</h4>
                                    </div>
                                    <p class="justify">The Post Graduate (MPT) course has been started in the academic year 2004 with the intake of 05 seats and the seats are enhanced up to 20 seats in the year 2015 further the MPT seats are enhanced up to 30 in the year 2022 with 7 specialities the MPT course in our college is  affiliated to  Rajiv Gandhi University of Health Sciences, Bangalore-41, Karnataka.</p>
                                    <p class="justify">The Masters Degree in Physiotherapy is a two years program with 7 specialities in our esteemed Institute. MPT teaching schedule consisting of classroom teaching, Seminars, Case presentation, Journal club, Self academic activities, Operation theatre posting, Radiology posting and Rotational clinical posting in the respective speciality and super speciality wards. In the first year schedule theoretical basis of physiotherapeutic approaches are discussed and refreshed along with research methodology, OT posting, Radiology posting, Ward posting  and Biostatistics classes. The students are rotated in all areas of clinical expertise during this period. During  first year the students are  required to choose their speciality study for their dissertation and submit a synopsis to the Rajiv Gandhi University of Health Sciences with in 6 months duration from the date of commencement. During the second year the students will be posted in their area of specialty. They are required to complete and submit their dissertation. The learning program includes seminars, journal reviews, case presentations, case discussions and classroom teaching. Some of the clinical postings are provided at other reputed centers in the country in order to offer a wider spectrum of experience. The students are encouraged to attend conference, workshop to enhance their knowledge during the course of study. University examinations are held at the end of second year. The MPT program at KIPT is designed to provide students with the opportunity to gain in depth knowledge and sound practical skills in the area of their specialization. The program provides post graduate students with advanced hands on techniques in their chosen areas of specialization. It exposes students to recent developments in the field and enhances their intellectual, research and analytical skills.</p>
                                    <div className="title-header" style={{ borderTopColor: "red" }}>
                                        <h5 className="title">Goals of Course</h5>
                                    </div>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Preparation of a postgraduate student towards his/her professional autonomy with self regulating discipline on par with global standards.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Formation of base of the professional practice by referral as well as first contact mode using evidence based practice.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Impartation of research basis in order to validate techniques & technology in practice of physiotherapy.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Acquainting a student with concept of quality care at the institutional as well as the community levels.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Inculcation of appropriate professional relationship in multidisciplinary set up, patient management and co partnership basis.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Preparation of students to address problems related to health education and community physiotherapy.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Practicing the concept of protection of rights of the community during referral as well as first contact practice.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Incorporation of concept of management in physiotherapy.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Experience in clinical training and undergraduate teaching partly.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Providing the honest, competent and accountable physiotherapy services to the communit.</li>
                                    </ul>
                                    <div className="title-header paddingtop-1" style={{ borderTopColor: "red" }}>
                                        <h4 className="title">Doctor of Philosophy (Ph.D)</h4>
                                    </div>
                                    <p class="justify">The Ph.D programme has been started in the academic year 2022 with the experienced guides in the specialities of Orthopedics and Neurology recognized and affiliated by Rajiv Gandhi University of Health Sciences, Bangalore-41, Karnataka.</p>
                                    <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Ph.D is the full time programme  as per UGC and RGUHS norms.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The student those who are successfully completed their Master Degree programme in a reputed institute and reputed university are eligible to have RGUHS Ph.D common entrance examination.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The students those who have cleared the entrance examination with pass percentage are eligible to select the guide with their concurrence from our esteemed institution.</li>
                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>The enrolled scholars are given rotatory posting in their respective speciality wards.</li>
                                    </ul>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

