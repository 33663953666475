import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Footer extends Component {
    render() {
        return (
            <footer className="footer widget-footer clearfix">
                <div className="second-footer ttm-textcolor-white ttm-bgcolor-darkgrey">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 widget-area">
                                <div className="widget widget_text clearfix">
                                    <h3 className="widget-title">About Our College</h3>
                                    <div className="textwidget widget-text">Kempegowda institute of physiotherapy firmly believes that the learning experience extends far beyond the four walls of the class rooms. Hence it has created to finest campuses.</div>
                                    <div className="textwidget widget-text"><br/>KEMEPEGOWDA INSTITUTE OF PHYSIOTHERAPY<br/>K.R.ROAD, V V PURAM,<br/>BANGALORE-04<br/><br/>Contact : 9845047646<br/>Office Land line : 080-26619960</div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area">
                                <div className="widget link-widget clearfix">
                                    <h3 className="widget-title">Quick Links</h3>
                                    <ul id="menu-footer-services">
                                        <li><Link to='/About-College'>About College</Link></li>
                                        <li><Link to='/Gallery'>Gallery</Link></li>
                                        <li><Link to='/Life-Facilities'>Facilities</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 widget-area res-767-mb-40">
                                <div className="widget flicker_widget clearfix">
                                    <h3 className="widget-title">Follow Us On</h3>
                                    <div className="textwidget widget-text">
                                        <div className="social-icons circle social-hover" style={{ paddingLeft: "1.5rem" }}>
                                            <ul className="list-inline" style={{ paddingLeft: "1.25rem" }}>
                                                <li className="social-instagram"><a href="https://www.instagram.com/kipt_ignite/" className="tooltip-top rounded-circle" target="_blank" data-tooltip="instagram"><i className="fab fa-instagram" /></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                    <div className="container-xl">
                        <div className="copyright" style={{ textAlign: "center" }}>
                            <span>Copyright © 2023&nbsp; by <a to="https://shoonyeka.com" target="_blank">Shoonyeka Solutions (OPC) Pvt Ltd</a></span>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}