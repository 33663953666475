import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class LifeOPD_IPD extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="OPD / IPD" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Our Kempegowda Institute of Physiotherapy out patient department is well equipped with advanced modalities and treatment techniques to provide quality care for the patients. Our multi speciality  out patient department is well spacious  with seperate Orthopedic Rehabilitation Department, Sports Rehabilitation Department, Neurological Rehabilitation Department,  Paediatric Rehabilitation Department, Cardio-Respiratory Rehabilitation  Department,   Community Based Rehabilitation Department, Geriatric Rehabilitation Department,   Research Lab  and Exercise Therapy  room.</p>
                                        <p className="about-para">Our Kempegowda Institute of Physiotherapy In-patient Department is attached with 1050 beded Kims Hospital & Research Center were optimal care and  rehabilitation  will be provided. </p>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <p className="about-para">There are seperate departments for Orthopedic, Surgery, Neuro-Medicine, Peadiatrics, OBG. Intensive Care Unit -  (Emergency ICU, Medicine ICU, Cardiac Care Unit, Respiratory ICU, Peadiatric ICU, Neonatal ICU) and Special wards for all the departments.</p>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-1.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-2.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-3.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-4.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-5.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-6.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-7.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/OPD-8.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>

            </div>
        )
    }
}


