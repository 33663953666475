import React, { Component } from 'react';
import NoData from "../Common/NoData";
import Loading from "../Common/Loading";
import { firestore } from '../../Config/FbConfig';
import { doc, getDoc } from 'firebase/firestore';
import ModalImage from "react-modal-image";

export default class FacultyDisplay extends Component {

    state = { Datas: [] }

    async componentDidMount() {
        //For Teaching
        const docRef = doc(firestore, "FacultiesandStaff", "Teaching");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            var Data = []
            Object.entries(docSnap.data()).forEach(([key, value]) => {
                if (value.Department === this.props.department) {
                    Data.push(Object.assign(
                        {
                            Id: key,
                            Priority: value.Priority != undefined ? value.Priority : "999",
                            Image: value.Image,
                            Name: value.Name,
                            Description: value.Description,
                            Designation: value.Designation,
                            Qualification: value.Qualification,
                            Link: value.Link
                        }))
                }
            });

            Data.length === 0 ? this.setState({ Datas: "NO_999" }) : this.setState({ Datas: Data.sort(function (a, b) { return a.Priority - b.Priority; }) })

        }

        else { this.setState({ Datas: "NO_999" }) }
    }

    render() {
        return (
            <div>
                <div className="section-title with-desc text-left clearfix">
                    <div className="title-header" style={{ borderTopColor: "red" }}>
                        <h4 className="title">Our Teaching Faculty</h4>
                    </div>
                </div>
                <div className="container">
                    <div className="row paddingtop-1">
                        {
                            this.state.Datas.length === 0 ? <Loading /> :
                                this.state.Datas === "NO_999" ? <NoData /> :
                                    this.state.Datas.map((data, i) => (
                                        <div className="col-lg-6" key={i}>
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="gallery-box">
                                                        <ModalImage className="swaraimage1" small={data.Image} large={data.Image} alt="Image" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-8" style={{ margin: "auto" }}>
                                                    <p style={{ color: "black", fontWeight: "bold" }}>{data.Name}</p>
                                                    <p style={{ color: "black" }}>
                                                        {data.Qualification}<br />
                                                        {data.Designation}<br />
                                                        <a href={data.Link} target="_blank">View Details</a>
                                                    </p>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    ))
                        }
                    </div>
                    <br />
                </div>
            </div>
        )
    }
}
