import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class LifeEducationFair extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="KIPT Education Fair" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/EducationFair.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Staff and Students of Kempegowda Institute Of Physiotherapy indulge themselves in some or the other education fairs educating the public about physiotherapy and its uses and efficiency. Recently we had been to an education fair which was conducted in Malleshwaram grounds by the Suvarna Shikshana Education Expo.</p>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/EducationFair-1.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/EducationFair-2.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/EducationFair-3.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/EducationFair-4.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}

