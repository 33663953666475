import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class LifeGraduation extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="KIPT Graduation Day" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-8 justify" style={{ margin: "auto" }}>
                                        <p className="about-para">Kempegopwda Institute Of Physiotherapy organises Gradutaion Day for UG and PG graduates in a grand way and embarks a new chapter in their lives.</p>
                                    </div>
                                </div>
                            </div>
                            <br />
                        </div>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-1.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-2.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-3.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-4.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-5.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-6.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-7.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-8.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-9.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-10.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-11.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-12.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-13.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-14.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-15.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-16.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <div className="row paddingtop-1">
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-17.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-18.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-19.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="ttm_single_image-wrapper">
                                    <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Graduation-20.jpg"} alt="single-img-eighteen" />
                                </div>
                            </div>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        )
    }
}

