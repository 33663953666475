import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarNav, MDBNavItem, MDBCollapse, MDBDropdownItem,
MDBHamburgerToggler } from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

class Mobilemenu extends Component {
  
state = {
  collapse1: false,
  collapseID: ''
}

toggleCollapse = collapseID => () => {
  this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
}

toggleSingleCollapse = collapseId => {
  this.setState({
    ...this.state,
    [collapseId]: !this.state[collapseId]
  });
}

render() {
  return (
    <Router>
        <MDBNavbar>
            <MDBHamburgerToggler id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/'}>Home</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-Management'}>About Management</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-College'}>About College</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-Chairman'}>About Chairman</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-Principal'}>About Principal</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/About-Rules'}>Rules and Regulations</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Mandatory-WGRC'}>Mandatory-WGRC</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/College-Cultural'}>College-Cultural</MDBDropdownItem></MDBNavItem>

                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Programme-BPT'}>Rules and Regulations</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Specialities-Musculoskeletal'}>Specialities</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Life-OPD-IPD'}>Life @ KIPT</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Campus-Companies'}>Placements</MDBDropdownItem></MDBNavItem>
                  <MDBNavItem><MDBDropdownItem href={process.env.PUBLIC_URL + '/Contact'}>Contact</MDBDropdownItem></MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
          </MDBNavbar>
    </Router>
    );
  }
}

export default Mobilemenu;