import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class ProgramPHD extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="Doctor of Philosophy (Ph.D)" image="banners/Programme.jpg" />


                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">Ph.D Programme was started in the year 2022-23 there are Three Ph.D Teachers out of which Two people are Rajiv Gandhi University of Health Sciences recognized Ph.D Guides in Orthopedics and Neurological Sciences.</p>
                            </div>
                            <ul className="ttm-list ttm-list-style-icon paddingtop-1">
                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Physotherapy in Orthopeadics - Dr.R.Raja, Professor</li>
                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Neurological Sciences - Dr.Prem Kumar B N, Associate Professor/Principal</li>
                            </ul>
                            <div className="ttm-service-description justify">
                                <p className="tab-desc">As per Rajiv Gandhi University of Health Sciences Rules and Regulations the Guide student ratio is 1:6 untill the 1 st Scholar awarded Ph.D Programme by RGUHS the Guide is not permitted to take the next Scholar. The Maximum limit of Scholar is 6 per Guide.</p>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

