import React, { Component } from 'react';
import Header from '../layout/Header';
import Banner from '../Common/Banner';
import SpecialitiesSidebar from './SpecialitiesSidebar';
import HodDisplay from './HodDisplay';
import FacultyDisplay from './FacultyDisplay';


export default class SpecialitiesCBR extends Component {
    render() {
        return (
            <div className="body_wrapper">

                <Header />
                <Banner title="Community Based Rehabilitation (CBR)" image="banners/Placement.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-lg-9 content-area order-lg-2">
                                <HodDisplay department="Community Based Rehabilitation (CBR)" />
                                <FacultyDisplay department="Community Based Rehabilitation (CBR)" />
                            </div>
                            <div className="col-lg-3 widget-area">
                                <SpecialitiesSidebar />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}