import React, { Component } from 'react'
import Header from '../layout/Header';
import Banner from '../Common/Banner';

export default class Library extends Component {
    render() {
        return (
            <div className="body_wrapper">
                <Header />
                <Banner title="KIPT Library" image="banners/Programme.jpg" />

                {/* Content */}
                <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                    <div className="container-xl paddingtop-2">
                        <div className="ttm-service-single-content-area">
                            <div className="ttm-service-description">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Library-1.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-7 justify" style={{ margin: "auto" }}>
                                        <div className="ttm-service-description justify">
                                            <ul className="ttm-list ttm-list-style-icon paddingtop-2">
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Staff reading room.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>P G reading room.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Interns reading room.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Rooms for librarian and other staff.</li>
                                                <li><i className="fa fa-check-circle ttm-textcolor-skincolor"></i>Digital Library.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">KIPT Library was established in 1979 with a modest number of books and other documents. Since its inception, the library has steadily developed its collection and presently the library has over 955 books, journals, dissertations, institutional repositories, previous years examination question papers, electronic resources like E-books, E-journals, CDROMS, Standards etc.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">Library strives to offer exceptional service by acquiring, organizing, and providing access to information resources specializing in the fields of Medical science and physiotherapy. Library employs state-of-the-art technologies to support learning, teaching, research and innovation. The library is a part of the institution that touches everyone-every student,faculty member, researcher, and in every academic field.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">KIMS Central Library functions as a centralized agency for procurement of books, journals and other documents required by all departments of the institution and learning community. Collection Development is done based on the recommendations of the Department Heads and feedbacks received from students and faculty members. Continuing efforts are directed towards building the collection to ensure that it remained relevant and up-to-date in support of teaching, learning and research needs. Emphasis is given for diversifying collection from being primarily print oriented to include video, multimedia and other digital materials.</p>
                                    </div>
                                    <div className="col-md-12 justify paddingtop-1">
                                        <p className="about-para">Library at KIPT has an exclusive collection of internationally acknowledged Physiotherapy books, Digital Library and the Internet Center where the students can gather medical and physiotherapy related information.</p>
                                    </div>
                                </div>
                                <div className="row paddingtop-1">
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Library-2.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Library-3.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Library-4.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="ttm_single_image-wrapper">
                                            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/images/lifekipt/Library-5.jpg"} alt="single-img-eighteen" />
                                        </div>
                                    </div>
                                </div>
                                <br />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
