import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCT8pG8d2GoVePWaA1Z0hSLX6IodXpeHr8",
  authDomain: "rvs-kipt.firebaseapp.com",
  projectId: "rvs-kipt",
  storageBucket: "rvs-kipt.appspot.com",
  appId: "1:981148683375:web:c7160422bb68659565519a",
  measurementId: "G-0YHP5VYJVX"
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export {auth, firestore , storage};